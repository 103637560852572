const ready = fn => {
  if (document.readyState != 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

const animateContent = () => {
  const wrapperEl = document.getElementById('wrapper');
  wrapperEl.classList.add('ready');
};

ready(animateContent);
